import FeatureGates from '@atlaskit/feature-gate-js-client';
import { UNSAFE_initIfNeeded } from '@atlassian/jira-feature-gates-unsafe-init/src/index.tsx';

let cache: Set<string>;
export default function killswitch(name: string): boolean {
	UNSAFE_initIfNeeded();

	try {
		if (!cache) {
			// Using this to access a dynamic parameter
			// Killswitching is known destructive, hence the lack of exposure
			const config = FeatureGates.getExperimentValue<Array<string>>('killswitch', 'value', [], {
				fireExperimentExposure: false,
			});
			cache = new Set(config.map((d: string) => d.toLowerCase()));
		}
		return cache.has(name.toLowerCase());
	} catch (e) {
		return false;
	}
}
